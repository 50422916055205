import React from "react";
import "./heading.css";

function Heading({ title, subtitle }) {
    return (
        <div className="flex flex-col gap-5 justify-center items-center w-full max-w h-fit">
            <div className="heading font-w-bold vipFont text-4xl sm:text-5xl">
                {title} <span className="text-primSky-500">{subtitle}</span>
            </div>
            <div className="underline-svg w-[340px] sm:w-[400px] inline-block ">
                <svg
                    // width="407"
                    // height="49"
                    className="w-full h-full "
                    viewBox="0 0 407 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className="path-vert"
                        d="M1 48C30.5 5.5 261 -22.5 406 32.3365"
                        stroke="#11BAF0"
                        strokeWidth="3"
                    />
                </svg>
            </div>
        </div>
    );
}

export default Heading;
