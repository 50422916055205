import React, { createContext, useContext, useEffect, useState } from "react";
import auth from "../services/authServices";
import http from "../services/httpServices";
import { isStudyPlannerEnabled } from "../services/defaultSettings";
import AuthContext from "./AuthContext";

const WeekPlannerContext = createContext();

export const WeekPlannerProvider = ({ children }) => {
    const [weeks, setWeeks] = useState([]);
    const [WeeksFound, setWeeksFound] = useState(0);

    const { token } = useContext(AuthContext);

    const findWeeks = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`/api/study_planner_weeks/check`, config);
        setWeeksFound(data);
    };

    useEffect(() => {
        if (isStudyPlannerEnabled && token) {
            findWeeks();
        }
    }, [token]);
    return (
        <WeekPlannerContext.Provider value={{ weeks, setWeeks, WeeksFound }}>
            {children}
        </WeekPlannerContext.Provider>
    );
};

export default WeekPlannerContext;
