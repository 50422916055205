import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../components/form/elements/InputField";
import { handleFormErrors, handleInputChange } from "../../../services/formServices";
import ExamContext from "../../../context/ExamContext";
import Button from "../../../components/ui/Button";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { useParams } from "react-router-dom";
import ExamOpenButton from "./ExamOpenButton";

const UserInquest = ({ handleGoToQuestion, previousResults }) => {
    const { exam, setExam } = useContext(ExamContext);
    const { id, section_id, exam_id } = useParams();
    const [data, setData] = useState({
        answer: 50,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isInputChanged, setIsInputChanged] = useState(false);
    const [feedback, setFeedback] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isInputChanged) {
            setErrors({
                answer: "لازم تقول اجابتك !",
            });
            return;
        }
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        try {
            const { data: response } = await http.post(
                `/api/sellables/course/${id}/sections/${section_id}/sectionables/${exam_id}/store_user_inquest_answer`,
                data,
                config
            );
            setFeedback(response.feedback);
            setExam({
                ...exam,
                sectionable: {
                    ...exam.sectionable,
                    has_pre_exam_inquest: 0,
                    has_pre_exam_inquest_answered: 1,
                },
            });
        } catch (error) {
            handleFormErrors(error.response, setIsLoading, setErrors, false);
        }
    };
    useEffect(() => {
        if (data.answer !== 50) {
            setIsInputChanged(true);
        }
    }, [data]);
    return (
        <>
            {exam.sectionable && exam.sectionable.has_pre_exam_inquest_answered ? (
                <div className="space-y-3">
                    <div className="rounded-md bg-cyan-500 text-white px-5 py-1 font-w-bold font-h3">
                        {feedback}
                    </div>
                    <ExamOpenButton
                        previousResults={previousResults}
                        exam={exam}
                        handleGoToQuestion={handleGoToQuestion}
                    />
                </div>
            ) : (
                <form className="space-y-3 flex-center-both flex-col" onSubmit={handleSubmit}>
                    <div className="font-smaller font-w-bold text-center">
                        لازم تجاوب السؤال ده الأول قبل فتح الإختبار :
                    </div>
                    <InputField
                        type="range"
                        id="answer"
                        placeholder="انت مراكم كام ٪ من المحاضرة اللي فاتت ؟"
                        beforePercentage="انت مراكم : "
                        afterPercentage=" من المحاضرة"
                        data={data}
                        onChange={handleInputChange}
                        setData={setData}
                        errors={errors}
                    />
                    <Button color="cyan" className=" py-1 px-5 font-w-bold" isLoading={isLoading}>
                        حفظ الإجابة
                    </Button>
                </form>
            )}
        </>
    );
};

export default UserInquest;
