import React, { createContext, useEffect, useState } from "react";
import auth from "../services/authServices";
import http from "../services/httpServices";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSecondaryLoading, setIsSecondaryLoading] = useState(false);
    const [isNonSeenNotifications, setIsNonSeenNotifcations] = useState(false);

    const getNotification = async () => {
        setIsSecondaryLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        let notificationsIds = notifications.map((notification) => notification.id);
        const { data } = await http.get("/api/notifications/latest/5", {
            ...config,
            params: {
                previous_notifications: notificationsIds,
            },
        });
        setNotifications([...notifications, ...data]);
        setIsLoading(false);
        setIsSecondaryLoading(false);
    };

    const setSeen = async (notification) => {
        if (notification.is_seen) {
            return;
        }
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`/api/notifications/${notification.id}/set_seen`, config);
        setNotifications(
            notifications.map((item) => {
                if (item.id === notification.id) {
                    return {
                        ...item,
                        is_seen: true,
                    };
                } else {
                    return item;
                }
            })
        );
    };

    useEffect(() => {
        getNotification();
    }, []);
    useEffect(() => {
        setIsNonSeenNotifcations(
            notifications.filter((notifcation) => !notifcation.is_seen).length > 0
        );
    }, [notifications]);

    return (
        <NotificationContext.Provider
            value={{
                isLoading,
                isSecondaryLoading,
                getNotification,
                notifications,
                setNotifications,
                isNonSeenNotifications,
                setSeen,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
