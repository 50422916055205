import React, { useContext } from "react";
import CenterIcon from "../../../components/ui/CenterIcon";
import { printRelativeDate } from "../../../utils/ar";
import NotificationContext from "../../../context/NotificationContext";
import { Link } from "react-router-dom";

const NotificationRow = ({ notification, isBorderBottom = true }) => {
    const { setSeen } = useContext(NotificationContext);
    const icons = {
        community_post_closed: (
            <div className="font-h3 text-green-500 px-2 flex items-start justify-center pt-1">
                <CenterIcon icon={"octicon:issue-closed-16"} />
            </div>
        ),
        community_post_approved: (
            <div className="font-h3 text-cyan-500 px-2 flex items-start justify-center pt-1">
                <CenterIcon icon={"bi:send-check-fill"} />
            </div>
        ),
        community_post_unclosed: (
            <div className="font-h3 text-yellow-500 px-2 flex items-start justify-center pt-1">
                <CenterIcon icon={"mingcute:unlock-line"} />
            </div>
        ),
        community_post_has_new_comment: (
            <div className="font-h3 text-cyan-500 px-2 flex items-start justify-center pt-1">
                <CenterIcon icon={"el:group"} />
            </div>
        ),
        community_comment_has_new_comment: (
            <div className="font-h3 text-cyan-500 px-2 flex items-start justify-center pt-1">
                <CenterIcon icon={"mingcute:comment-fill"} />
            </div>
        ),
    };
    return (
        <div className="">
            <Link to={notification.url_source ? notification.url_source : ""}>
                <div
                    className={`flex relative flex-row cursor-pointer py-1 smooth clr-text-primary hover:bg-secondary-container ${
                        !notification.is_seen ? "bg-slate-200 dark:bg-slate-800 " : ""
                    }`}
                    onClick={() => setSeen(notification)}
                >
                    {icons[notification.type]}
                    <div className="w-full flex flex-col">
                        <div className="text-[10px] font-w-bold">{notification.title}</div>
                        <div className="text-[8px] clr-text-secondary">
                            {printRelativeDate(notification.created_at)}
                        </div>
                    </div>
                    {!notification.is_seen ? (
                        <>
                            <div className="absolute right-0 top-0 p-1">
                                <div className="h-2 w-2 rounded-full bg-green-500"></div>
                            </div>
                            <div className="absolute left-0 bottom-0 p-1">
                                <CenterIcon icon="ph:eye-closed-duotone" />
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </Link>
            {isBorderBottom ? (
                <div className="px-2 pt-2">
                    <div className="h-[1px] w-full rounded-md bg-secondary-container smooth"></div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default NotificationRow;
