import React, { useContext, useState } from "react";
import Form from "../components/form/elements/Form";
import InputField from "../components/form/elements/InputField";
import Button from "../components/ui/Button";
import auth from "../services/authServices";
import {
    getFormData,
    handleFormErrors,
    handleFormSubmit,
    handleInputChange,
} from "../services/formServices";
import http from "../services/httpServices";
import AuthContext from "../context/AuthContext";
import CenterIcon from "../components/ui/CenterIcon";

const initialState = {
    description: "",
    file: "",
};
const CommunityCreatePostForm = ({ category, posts, setPosts, setCategory }) => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isShowForm, setIsShowForm] = useState(false);

    const { user } = useContext(AuthContext);
    const handleSubmit = async (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, async () => {
            setIsLoading(true);
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            try {
                const formData = getFormData(data);
                let { data: response } = await http.post(
                    `/api/community_categories/${category["id"]}/posts`,
                    formData,
                    config
                );

                response = {
                    ...response,
                    user: { full_name: user.full_name },
                    community_comments: [],
                };
                if (category.is_required_approvment_to_post) {
                    let newCategory = {
                        ...category,
                        community_posts: [response, ...category.community_posts],
                    };
                    setCategory(newCategory);
                } else {
                    setPosts([response, ...posts]);
                }
                setIsLoading(false);
                setIsShowForm(false);
                setData(initialState);
            } catch ({ response }) {
                handleFormErrors(response, setIsLoading, setErrors);
            }
        });
    };
    return (
        <div className="pb-2">
            <Form
                className="flex-center-both w-full max-w-xl mx-auto px-4 py-4 rounded-lg border border-slate-50 dark:border-cyan-900 bg-primary-container clr-text-primary smooth shadow-lg"
                onSubmit={handleSubmit}
            >
                {isShowForm ? (
                    <div className="pt-10 w-full space-y-4 relative">
                        <div
                            className="absolute top-0 right-0 p-1 cursor-pointer"
                            onClick={() => setIsShowForm(false)}
                        >
                            <CenterIcon icon="tabler:x" />
                        </div>
                        <InputField
                            id="description"
                            autoFocus={true}
                            type="textarea"
                            placeholder="انشر موضوع جديد"
                            setData={setData}
                            data={data}
                            errors={errors}
                            onChange={handleInputChange}
                        />

                        <div className="space-y-2">
                            <div className="">هل تريد ارفاق صورة؟</div>
                            <InputField
                                id="image"
                                type="file"
                                placeholder="ارفق صورة"
                                isRequired={false}
                                inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                                setData={setData}
                                data={data}
                                errors={errors}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="flex-center-both">
                            <Button color="cyan" className="w-full" isLoading={isLoading}>
                                {category.is_required_approvment_to_post
                                    ? "ارسال طلب نشر"
                                    : "نشر الآن !"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-row space-x-2 space-x-reverse w-full">
                        <div className="flex-center-both">
                            <CenterIcon
                                icon="healthicons:ui-user-profile"
                                className="text-cyan-500 opacity-50 font-h1"
                            />
                        </div>
                        <div
                            className="w-full rounded-lg bg-cyan-400 bg-opacity-40 hover:bg-opacity-60 smooth clr-text-secondary py-2 px-2 font-w-bold cursor-pointer"
                            onClick={(e) => {
                                setIsShowForm(true);
                            }}
                        >
                            اكتب سؤالك هنا و شارك بـ إستفسارك
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default CommunityCreatePostForm;
