import React from "react";
import { Outlet } from "react-router-dom";
// import HeaderSection from "../components/ui/HeaderSection";
// import Container from "../components/ui/Container";

const CommunityHome = () => {
    return (
        <>
            <div className="negative-nav-margin posisitve-nav-padding-top">
                {/* <Container className="py-10 pb-10 space-y-0">
                    <HeaderSection></HeaderSection>
                </Container> */}
                <div className="w-full min-h-screen">
                    <Outlet />
                </div>
                {/* <Container></Container> */}
            </div>
        </>
    );
};

export default CommunityHome;
