import React, { useContext } from "react";
import CenterIcon from "../../components/ui/CenterIcon";
import NotificationContext from "../../context/NotificationContext";

const NotificationsIcon = () => {
    const { isNonSeenNotifications } = useContext(NotificationContext);
    return (
        <div className="relative clr-text-primary smooth">
            <CenterIcon className="font-h1" icon={"ph:bell-duotone"} />
            {isNonSeenNotifications ? (
                <>
                    <div className="absolute right-0 top-0 h-3 w-3 bg-rose-500 rounded-full"></div>
                    <div className="absolute right-0 top-0 h-3 w-3 bg-rose-500 animate-ping rounded-full"></div>
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default NotificationsIcon;
