import React from "react";
import CenterIcon from "../components/ui/CenterIcon";
import { Link } from "react-router-dom";
import { printRelativeDate, printUnit } from "../utils/ar";

import groupIcon from "../assets/group-icon.svg";

const CommunityCategoryCard = ({ category }) => {
    return (
        <Link to={`./category/${category.id}`}>
            <div className="flex flex-col flex-center-both space-y-2 bg-cyan-400 bg-opacity-50 hover:bg-opacity-90 text-slate-900 dark:text-slate-50 rounded-md smooth p-5 h-full">
                <div className="max-w-md">
                    <img className="" src={groupIcon} alt={"group icon"} />
                </div>
                <div className="relative flex width items-center justify-center min-h-[4rem]">
                    <div className="absolute w-1/2 inset-0 z-0 flex items-center justify-end">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-16"
                            viewBox="0 0 231 101"
                            fill="none"
                        >
                            <ellipse
                                cx="115.5"
                                cy="50.5"
                                rx="115.5"
                                ry="50.5"
                                className="fill-secYellow-600 dark:fill-secYellow-400 smooth saturate-50"
                            />
                        </svg>
                    </div>
                    <h1 className="md:font-w-bold relative z-10 text-center font-w-bold font-h3">
                        {category.name}
                    </h1>
                </div>
                <div className="">{category.description}</div>
                <div className="flex-center-both space-x-2 space-x-reverse !hidden">
                    <div className="flex-center-both space-y-2">
                        <div className="md:w-20 w-10 md:h-20 h-10 rounded-full bg-secondary-container flex-center-both smooth">
                            <CenterIcon
                                className="w-full h-full clr-text-secondary smooth "
                                iconProperties={{ className: "h-3/4 w-3/4" }}
                                icon="ic:twotone-group"
                            />
                        </div>
                    </div>
                    <div className="space-y-1">
                        <div className="font-w-bold font-h3">{category.name}</div>
                        <div className="clr-text-secondary smooth">
                            <div className="font-w-bold">{category.description}</div>

                            <div className="font-smaller flex space-x-1 space-x-reverse">
                                <CenterIcon icon="icon-park-twotone:time" className="pt-0.5" />
                                <span>
                                    آخر نشاط داخل المجموعة {printRelativeDate(category.updated_at)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-center-both !hidden">
                    <div className="p-1 bg-secondary-container clr-text-secondary smooth rounded-md shadow-md flex-center-both flex-col space-y-2 text-center font-smaller">
                        <div className="">عدد المواضيع</div>
                        <div>{printUnit(category.community_posts_count, "موضوع")}</div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

// ic:twotone-group
export default CommunityCategoryCard;
