import React, { useEffect, useState } from "react";
import { submitTypes } from "../../services/adminServices";
import auth from "../../services/authServices";
import { isMultiYear, isSubjectSubscriptionable } from "../../services/defaultSettings";
import http from "../../services/httpServices";
import InputIcon from "../../components/form/elements/InputIcon";
import { years } from "../../services/yearSevices";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
const initialState = {
    name: "",
    description: "",
    department_id: 0,
    year: 3,
    date_from: "",

    study_planner_week_id: "",
    choosen_year: 3,
    choosen_department_id: 0,
    submit_type: 0,
};

const StudyPlannerWeekEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputFields, setInputFields] = useState([]);

    const [studyPlannerWeeks, setStudyPlannerWeeks] = useState([]);

    const [departments, setDepartments] = useState([]);

    const getDepartments = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/admin/years/${
                data.submit_type !== "store" ? data.choosen_year : data.year
            }/departments/options`,
            config
        );
        setDepartments(response);
    };

    const getStudyPlannerWeeks = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(
            isSubjectSubscriptionable
                ? `/api/admin/departments/${data.choosen_department_id}/study_planner_weeks/options`
                : `/api/admin/years/${data.choosen_year}/study_planner_weeks/options`,
            config
        );
        setStudyPlannerWeeks(response);
    };

    const getStudyPlannerWeekInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            `/api/study_planner_weeks/${data.study_planner_week_id}`,
            config
        );
        let result = {};
        Object.keys(response).forEach((element) => {
            if (["name", "description", "department_id", "year", "date_from"].includes(element)) {
                result[element] = response[element];
            }
        });

        setData({ ...data, ...result });
    };

    useEffect(() => {
        if (
            data.submit_type &&
            data.submit_type !== "store" &&
            data.choosen_year &&
            (data.choosen_department_id || !isSubjectSubscriptionable)
        ) {
            getStudyPlannerWeeks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.choosen_year, data.choosen_department_id, data.submit_type]);

    useEffect(() => {
        if (isSubjectSubscriptionable) {
            getDepartments();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.year, data.choosen_year, data.submit_type]);

    useEffect(() => {
        if (data.study_planner_week_id > 0 && data.submit_type && data.submit_type !== "store") {
            getStudyPlannerWeekInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.study_planner_week_id, data.submit_type]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];

        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                if (isMultiYear) {
                    fields = [
                        ...fields,
                        {
                            id: "choosen_year",
                            placeholder: "اختر الصف الدراسي",
                            type: "select",
                            options: years,
                        },
                    ];
                }

                if (isSubjectSubscriptionable) {
                    fields = [
                        ...fields,
                        {
                            id: "choosen_department_id",
                            placeholder: "اختر الشعبه",
                            type: "select",
                            options: departments,
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "study_planner_week_id",
                        placeholder: "اختر الاسبوع",
                        type: "select",
                        options: studyPlannerWeeks,
                    },
                ];

                if (data.submit_type === "update") {
                    if (data.study_planner_week_id > 0) {
                        fields = [
                            ...fields,
                            {
                                id: "name",
                                placeholder: "اسم الاسبوع",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            {
                                id: "description",
                                placeholder: "وصف/ملاحظات الاسبوع",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                                type: "textarea",
                            },
                            // {
                            //     id: "year",
                            //     placeholder: "السنه الدراسية",
                            //     options: years,
                            //     type: "select",
                            // },
                        ];
                        if (isSubjectSubscriptionable) {
                            fields = [
                                ...fields,
                                {
                                    id: "department_id",
                                    placeholder: "الشعبه",
                                    options: departments,
                                    type: "select",
                                },
                            ];
                        }
                        fields = [
                            ...fields,
                            {
                                id: "date_from",
                                placeholder: "بدايه الاسبوع",
                                type: "datepicker",
                                isOnlyDate: true,
                                filterDateFunction: (date) => {
                                    const day = date.getDay();
                                    return day === 6;
                                },
                            },
                        ];
                    }
                }
            }

            if (data.submit_type && data.submit_type === "store") {
                fields = [
                    ...fields,
                    {
                        id: "name",
                        placeholder: "اسم الاسبوع",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                    {
                        id: "description",
                        placeholder: "وصف/ملاحظات الاسبوع",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                        type: "textarea",
                    },
                    {
                        id: "year",
                        placeholder: "السنه الدراسية",
                        options: years,
                        type: "select",
                    },
                ];
                if (isSubjectSubscriptionable) {
                    fields = [
                        ...fields,
                        {
                            id: "department_id",
                            placeholder: "الشعبه",
                            options: departments,
                            type: "select",
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "date_from",
                        placeholder: "بدايه الاسبوع",
                        type: "datepicker",
                        isOnlyDate: true,
                        filterDateFunction: (date) => {
                            const day = date.getDay();
                            return day === 6;
                        },
                    },
                ];
            }
        }
        setInputFields(fields);
    }, [
        data.submit_type,
        departments,
        data.study_planner_week_id,
        data.choosen_year,
        data.choosen_department_id,
        studyPlannerWeeks,
    ]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/study_planner_weeks";
            const crudItemName = "اسبوع المذاكره";

            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.study_planner_week_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="auto"
                submitType={data.submit_type}
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default StudyPlannerWeekEditing;
