import React, { useContext, useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";
import { isMultiYear } from "../../services/defaultSettings";
import { getYearPlaceHolder } from "../../services/yearSevices";
import { printFullDate } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import modal from "../../services/modalServices";
import { baseURL } from "../../config";
import auth from "../../services/authServices";
import { description, printGovernment, printUnit } from "../../utils/ar";
import AuthContext from "../../context/AuthContext";

const EssayQuestionsReviewsTable = () => {
    const [exams, setExams] = useState([]);
    const [editableData, setEditableData] = useState(null);
    const [isToEditData, setIsToEditData] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const { admin } = useContext(AuthContext);
    const handleClick = async (id, value) => {
        // console.log(api);
        // return;
        setIsLoading(true);
        modal.message({
            title: "هل انت متأكد؟",
            text: `هل انت متأكد من تصحيح السؤال بـ ${printUnit(value, "درجة")} ? `,

            icon: "warning",
            buttons: {
                confirm: "تأكيد",
                cancel: "إلغاء",
            },
            callback: async (e) => {
                if (e && e !== "cancel") {
                    try {
                        const token = auth.getAdminToken();
                        const config = auth.getAdminAuthConfig(token);
                        // eslint-disable-next-line no-unused-vars
                        const { data } = await http.post(
                            `api/essay_question_review`,
                            { answer_id: id, value },
                            config
                        );
                        modal.message({
                            title: "تم تنفيذ طلبك بنجاح",
                            text: data.message,
                            icon: "success",
                            button: "حسنًا",
                        });
                        let newData = editableData.map((row) => {
                            if (row.id === id) {
                                return {
                                    ...row,
                                    is_reviewed: data.answer.is_reviewed,
                                    reviewed_result: data.answer.reviewed_result,
                                    last_reviewed_by: admin,

                                    // exam_result: {
                                    //     ...row.exam_result,
                                    //     result: data.exam_result.result,
                                    // },
                                };
                            } else {
                                return row;
                            }
                        });
                        setIsLoading(false);
                        setEditableData(newData);
                        setIsToEditData(true);
                    } catch (error) {}
                } else {
                    setIsLoading(false);
                }
                // if (e.isConfirmed) {
                //     try {
                //         const token = auth.getAdminToken();
                //         const config = auth.getAdminAuthConfig(token);
                //         // eslint-disable-next-line no-unused-vars
                //         const { data } = await http.post(
                //             `api/account_creation_requests/${id}/${api}`,
                //             { message: e.value },
                //             config
                //         );
                //         modal.message({
                //             title: "تم تنفيذ طلبك بنجاح",
                //             text: data.message,
                //             icon: "success",
                //             button: "حسنًا",
                //         });
                //         let newData = editableData.map((row) => {
                //             if (row.id === id) {
                //                 return {
                //                     ...row,
                //                     request_status: api === "decline" ? "declined" : "accepted",
                //                 };
                //             } else {
                //                 return row;
                //             }
                //         });
                //         setEditableData(newData);
                //         setIsToEditData(true);
                //         // setIndex(index + 1);
                //     } catch (error) {}
                // }
            },
        });
    };

    const requestStatusPlaceHolder = {
        pending: <div className="px-2 py-1 rounded-md bg-blue-500 text-white">معلق</div>,
        accepted: <div className="px-2 py-1 rounded-md bg-green-500 text-white">مقبول</div>,
        declined: <div className="px-2 py-1 rounded-md bg-rose-500 text-white">مرفوض</div>,
    };

    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم الطالب",
            reorder: true,
            selector: (row) => (row.user ? row.user.full_name : ""),
            sortable: true,
            sortField: "user_id",
        },
        {
            name: "رقم الهاتف",
            reorder: true,
            selector: (row) => (row.user ? row.user.phone : ""),
            // sortable: true,
            // sortField: "phone",
        },
        // {
        //     name: "اسم الامتحان",
        //     reorder: true,
        //     selector: (row) => (row.exam ? row.exam.name : ""),
        //     sortable: true,
        //     sortField: "exam_id",
        // },
        // {
        //     name: "درجة الطالب",
        //     reorder: true,
        //     selector: (row) => (row.exam_result ? row.exam_result.result : ""),
        //     // sortable: true,
        //     // sortField: "phone",
        // },
        {
            name: "رأس السؤال",
            reorder: true,
            selector: (row) => row.title,
            sortable: true,
            sortField: "title",
        },
        {
            name: "درجة السؤال",
            reorder: true,
            selector: (row) => row.score,
            sortable: true,
            sortField: "score",
        },
        {
            name: "اجابة الطالب",
            reorder: true,
            selector: (row) => description(row.selected_answer),
            sortable: true,
            sortField: "selected_answer",
        },

        {
            name: "تمت مراجعة السؤال؟",
            reorder: true,
            selector: (row) =>
                !row.is_reviewed
                    ? "لم يتم مراجعة السؤال"
                    : "تم مراجعة السؤال و اعطاء : " + printUnit(row.reviewed_result, "درجة"),
            sortable: true,
            sortField: "is_reviewed",
        },
        {
            name: "اوامر",
            reorder: true,
            selector: (row) => (
                <div className="space-y-2">
                    {[...Array(10).keys()].map((value, index) => {
                        if (value <= row.score) {
                            return (
                                <Button
                                    onClick={() => handleClick(row.id, value)}
                                    color={value === 0 ? "rose" : "green"}
                                    className={`py-1 px-3 block w-full`}
                                    isLoading={isLoading}
                                >
                                    <div>{printUnit(value, "درجة")}</div>
                                </Button>
                            );
                        } else return "";
                    })}
                </div>
            ),
            sortable: true,
            sortField: "request_status",
        },
        {
            name: "درجة الطالب في السؤال بعد المراجعة",
            reorder: true,
            selector: (row) =>
                !row.is_reviewed ? "لم يتم مراجعة السؤال" : printUnit(row.reviewed_result, "درجة"),
            sortable: true,
            sortField: "reviewed_result",
        },
        {
            name: "الادمن اللي راجع السؤال",
            reorder: true,
            selector: (row) =>
                !row.is_reviewed
                    ? "لم يتم مراجعة السؤال"
                    : row.last_reviewed_by
                    ? row.last_reviewed_by.email
                    : "تم حذف الادمن",
            sortable: true,
            sortField: "last_reviewed_by_id",
        },
    ];
    columns = [
        ...columns,
        {
            name: "تاريخ اجابة الطلب",
            reorder: true,
            selector: (row) => row.created_at,
            format: (row) => printFullDate(row.created_at),
            sortable: true,
            sortField: "created_at",
        },
    ];

    const initialState = {
        phone: "",
        review_status: "not_reviewed",
        question_id: 0,
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);

    async function getExams() {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data } = await http.get("/api/exams_with_essay_answers/options", config);
        setExams(data);
    }
    useEffect(() => {
        getExams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    {/* <InputField
                        id="government_id"
                        placeholder="المحافظة"
                        type="select"
                        options={[
                            {
                                value: 0,
                                text: "الكل",
                            },
                            ...governments,
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    /> */}
                    <InputField
                        id="review_status"
                        placeholder="حالة الطلب"
                        type="select"
                        options={[
                            {
                                value: "all",
                                text: "الجميع",
                            },
                            {
                                value: "not_reviewed",
                                text: "لم يتم المراجعة",
                            },
                            {
                                value: "reviewed",
                                text: "تمت المراجعة",
                            },
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    <InputField
                        id="exam_id"
                        placeholder="الامتحان"
                        type="select"
                        options={exams}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/essay_questions/paginate`}
                    // toEdit={}
                    isToEditData={isToEditData}
                    setIsToEditData={setIsToEditData}
                    editableData={editableData}
                    setEditableData={setEditableData}
                    columns={columns}
                    filterData={filterData}
                />
            </div>
        </AdminContainer>
    );
};

export default EssayQuestionsReviewsTable;
