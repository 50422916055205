import React, { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import Button from "../../components/ui/Button";
import RemoteTable from "../../components/ui/RemoteTable";
import http from "../../services/httpServices";
// import { isMultiYear } from "../../services/defaultSettings";
// import { getYearPlaceHolder } from "../../services/yearSevices";
import { printFullDateTime } from "../../utils/time";
import InputField from "../../components/form/elements/InputField";
import { handleInputChange } from "../../services/formServices";
import auth from "../../services/authServices";
import { Link } from "react-router-dom";
import { years } from "../../services/yearSevices";
import {
    isCenterUsers,
    isMultiYear,
    isSubjectSubscriptionable,
} from "../../services/defaultSettings";
import { printUnit } from "../../utils/ar";

const CourseUsersStatistics = () => {
    const [dataSets, setDataSets] = useState([]);
    const [remoteData, setRemoteData] = useState([]);
    useEffect(() => {
        let columns = [
            {
                name: "التسلسل",
                reorder: true,
                selector: (row) => row.id,
                sortable: true,
                sortField: "id",
            },
            {
                name: "اسم الطالب",
                reorder: true,
                selector: (row) => row.user.full_name,
            },
            {
                name: "رقم هاتف الطالب",
                reorder: true,
                selector: (row) => (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.user.id}`}
                    >
                        0{row.user.phone}
                    </Link>
                ),
            },
            {
                name: "رقم هاتف ولى الأمر",
                reorder: true,
                selector: (row) => <span>0{row.user.father_phone}</span>,
            },
        ];
        if (isCenterUsers) {
            columns = [
                ...columns,
                {
                    name: "اسم السنتر",
                    reorder: true,
                    selector: (row) => (row.user.center ? row.user.center.name : ""),
                },
            ];
        }
        // console.log(remoteData);
        if (remoteData.sections) {
            remoteData.sections.forEach((section, index) => {
                columns = [
                    ...columns,
                    {
                        name: section.name,
                        reorder: true,
                        selector: (row) => (
                            <>
                                <div className="p-1 border rounded-md border-blue-500">
                                    <div className="w-full rounded-md bg-rose-600 text-white flex-center-both text-center text-[10px] px-2 py-1 flex-col space-y-2">
                                        <div className="font-w-bold">الفيديوهات :</div>
                                        <div className="flex-center-both flex-col ">
                                            <div>عدد فيديوهات اللي شافها</div>
                                            <div className="font-w-bold text-[16px]">
                                                {row.sections[index].videos_watched_count} من{" "}
                                                {printUnit(
                                                    row.sections[index].videos_count,
                                                    "فيديو"
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full rounded-md bg-blue-600 text-white flex-center-both text-center text-[10px] px-2 py-1 flex-col space-y-2 mt-2">
                                        <div className="font-w-bold">الامتحانات :</div>
                                        <div className="flex-center-both flex-col ">
                                            <div>عدد الاختبارات اللي فتحها</div>
                                            <div className="font-w-bold text-[16px]">
                                                {row.sections[index].exams_exammed_count} من{" "}
                                                {printUnit(
                                                    row.sections[index].exams_count,
                                                    "اختبار"
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-center-both flex-col ">
                                            <div>متوسط درجاته في الاختبار</div>
                                            <div className="font-w-bold text-[16px]">
                                                {row.sections[index].exams_average_results} %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ),
                        sortable: false,
                    },
                ];
            });
        }

        columns = [
            ...columns,
            {
                name: "وقت الاشتراك",
                reorder: true,
                selector: (row) => printFullDateTime(row.created_at),
                sortable: false,
            },
        ];

        setDataSets(columns);
    }, [remoteData]);

    const initialState = {
        full_name: "",
        phone: "",
        teacher_id: 0,
        year: 0,
        course_id: 0,
    };

    const [filterData, setFilterData] = useState(initialState);
    const [data, setData] = useState(initialState);

    const [teachers, setTeachers] = useState([]);
    const [courses, setCourses] = useState([]);

    const getTeachers = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/admin/teachers/options`, config);
        setTeachers(response);
    };

    const getCourses = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        let result;

        result = isSubjectSubscriptionable
            ? data.year === 0
                ? await http.get(`/api/teachers/${data.teacher_id}/courses/options`, config)
                : await http.get(
                      `/api/teachers/${data.teacher_id}/years/${data.year}/courses/options`,
                      config
                  )
            : data.year === 0
            ? await http.get(`/api/courses/options`, config)
            : await http.get(`/api/years/${data.year}/courses/options`, config);
        const { data: response } = result;
        setCourses(response);
    };

    useEffect(() => {
        isSubjectSubscriptionable && getTeachers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if ((isSubjectSubscriptionable && data.teacher_id) || !isSubjectSubscriptionable) {
            getCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.year, data.teacher_id]);
    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    {/* 
                    <InputField
                        id="full_name"
                        placeholder="اسم الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    */}
                    {isSubjectSubscriptionable && (
                        <InputField
                            id="teacher_id"
                            type="select"
                            placeholder="اختر المدرس"
                            options={teachers}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className="lg:col-span-2"
                        />
                    )}
                    {isMultiYear && (
                        <InputField
                            id="year"
                            type="select"
                            placeholder="اختر العام الدرسي"
                            options={[
                                {
                                    value: 0,
                                    text: "كل السنين",
                                },
                                ...years,
                            ]}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className="lg:col-span-2"
                        />
                    )}
                    <InputField
                        id="course_id"
                        type="select"
                        placeholder="اختر الكورس"
                        options={courses}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setRemoteData(false);
                        setFilterData(data);
                    }}
                    isDisabled={data.course_id == 0 ? true : false}
                >
                    {/* {console.log(filterData.course_id == 0 ? true : false)} */}
                    بحث
                </Button>
                {filterData.course_id == 0 ? (
                    <div className="flex-center-both w-full">
                        <div className="p-20 bg-yellow-500 bg-opacity-10 rounded-md border-2 border-yellow-500 clr-text-primary smooth text-center font-h3">
                            يرجى اختيار الكورس و الضغط على بحث لمشاهدة الاحصائيات
                        </div>
                    </div>
                ) : (
                    <>
                        <RemoteTable
                            api={`/api/courses/${filterData.course_id}/statistics/paginate`}
                            columns={dataSets}
                            filterData={filterData}
                            setExtraData={setRemoteData}
                        />
                    </>
                )}
            </div>
        </AdminContainer>
    );
};

export default CourseUsersStatistics;
