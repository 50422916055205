import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import auth from "../../../services/authServices";
import { autoChangeRandomNames } from "../../../services/defaultSettings";
import http from "../../../services/httpServices";

const ValidNameCheck = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        user,
        isCheckedInvalidName,
        setIsCheckedInvalidName,
        isValidName,
        setIsValidName,
        isCheckedUnSyncedAccount,
        setIsCheckedUnSyncedAccount,
        isSyncedAccount,
        setIsSyncedAccount,
        setUser,
    } = useContext(AuthContext);

    const checkIfValidUsername = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data: result } = await http.get("/api/user", config);
            // console.log(result.user.full_name);
            const { user: userData } = result;
            if (
                userData.full_name === "random name" ||
                userData.first_name === "random" ||
                userData.last_name === "name"
            ) {
                setIsCheckedInvalidName(true);
                navigate("/edit_my_name");
            } else {
                setIsValidName(true);
            }
        } catch (e) {}
    };
    const checkIfSyncedAccount = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data: result } = await http.get("/api/user", config);
            // console.log(result.user.full_name);
            const { user: userData } = result;
            if (userData.is_already_migrated) {
                setIsSyncedAccount(true);
                auth.logout();
                setUser({});
                auth.authenticate(result.data.token, result.data.user);
                setUser({
                    ...result.data.user,
                });
            } else {
                setIsCheckedUnSyncedAccount(true);
                navigate("/edit_my_phone");
            }
            // if (
            //     userData.creation_method === "insert_auto" &&
            //     userData.insert_auto_type === "center_to_migrate" &&
            //     !userData.is_insert_auto_migrated
            // ) {
            // } else {

            //     // auth.logout();
            //     // setUser({});
            //     // auth.setPrepaidCoursesStoreSeen(false);
            // }
        } catch (e) {}
    };

    useEffect(() => {
        if (user.creation_method === "insert_auto") {
            if (user.insert_auto_type === "insert_auto_code") {
                const token = auth.getToken();
                if (
                    autoChangeRandomNames &&
                    !isValidName &&
                    !location.pathname.includes("edit_my_name") &&
                    token
                ) {
                    if (isCheckedInvalidName) {
                        navigate("/edit_my_name");
                    } else {
                        checkIfValidUsername();
                    }
                }
            } else if (user.insert_auto_type === "center_to_migrate") {
                const token = auth.getToken();
                if (
                    autoChangeRandomNames &&
                    !isSyncedAccount &&
                    !location.pathname.includes("edit_my_phone") &&
                    token
                ) {
                    if (isCheckedUnSyncedAccount) {
                        navigate("/edit_my_phone");
                    } else {
                        checkIfSyncedAccount();
                    }
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, isValidName, isSyncedAccount]);

    return <></>;
};

export default ValidNameCheck;
