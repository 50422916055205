import React, { useContext, useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import UserSearchForm from "./UserSearchForm";
import AdminForm from "../../components/ui/AdminForm";
import {
    handleFormErrors,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import auth from "../../services/authServices";
import { useLocation, useNavigate } from "react-router-dom";
import InputIcon from "../../components/form/elements/InputIcon";
import modal from "../../services/modalServices";
import http from "../../services/httpServices";
import AuthContext from "../../context/AuthContext";
import Button from "../../components/ui/Button";

const LoginAsUser = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [isBlocked, setIsBlocked] = useState(false);
    const [blockageMessage, setBlockageMessage] = useState("");

    const { setUser: authUser } = useContext(AuthContext);

    const [data, setData] = useState({
        phone: "",
    });

    const [errors, setErrors] = useState({});
    const [inputFields, setInputFields] = useState([]);

    useEffect(() => {
        let fields = [];

        fields = [
            {
                id: "phone",
                placeholder: "تسجيل بواسطة رقم التليفون",
                icon: <InputIcon icon="ant-design:phone-filled" />,
            },
        ];

        setInputFields(fields);
    }, [data.phone]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            handleLoggingAsUser();
        });
    };

    const navigate = useNavigate();

    const handleLoggingAsUser = async () => {
        const adminToken = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(adminToken);

        try {
            setIsLoading(false);
            const toSendUser = { ...data };
            toSendUser["phone"] = parseInt(data["phone"]);
            const result = await http.post("/api/users/login_as_user", toSendUser, config);
            auth.authenticate(result.data.token, result.data.user);
            authUser({
                ...result.data.user,
            });
            setIsLoading(false);
            modal.message({
                title: "تم العثور علي المستخدم وتسجيل دخولك",
                text: "الاسم : " + result.data.user.full_name,
                callback: () => {
                    navigate("/home");
                },
            });
        } catch ({ response }) {
            handleFormErrors(response, setIsLoading, setErrors);
            if (response.status === 400 && response.data.error === "account_blocked") {
                setIsBlocked(true);
                setBlockageMessage(response.data.blockage_message);
            }
        }
    };
    return (
        <AdminContainer>
            {isBlocked && (
                <div className="py-20 space-y-4">
                    <div className="rounded-md bg-rose-500 flex-center-both clr-white py-3">
                        تم حظر الحساب الخاص بذلك الطالب من الموقع !
                    </div>
                    <div className="flex-center-both py-4">
                        رقم الهاتف الخاص بالطالب :{" "}
                        <span className="font-w-bold pr-1">{data.phone}</span>
                    </div>
                    <div className="rounded-md bg-yellow-500 flex-center-both clr-white py-3">
                        سبب الحظر : <span className="font-w-bold pr-1">{blockageMessage}</span>
                    </div>
                    <div className="flex-center-both">
                        <Button color="rose" onClick={() => setIsBlocked(false)}>
                            اعادة المحاولة
                        </Button>
                    </div>
                </div>
            )}
            <AdminForm onSubmit={handleSubmit} isLoading={isLoading} buttonText="أدخل الأن">
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default LoginAsUser;
