import React, { useEffect, useMemo, useState } from "react";
import { submitTypes } from "../../services/adminServices";
import auth from "../../services/authServices";
import { isStoreLocatorAvailable } from "../../services/defaultSettings";
import http from "../../services/httpServices";
import InputIcon from "../../components/form/elements/InputIcon";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import Select from "react-select";

const initialState = {
    store_locatore_id: 0,
    name: "",
    address: "",
    phone: 0,
    government_id: 2,
};
const StoreLocatorEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [inputFields, setInputFields] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [governments, setGovernments] = useState([]);
    const [stores, setStores] = useState([]);

    const getStores = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        try {
            const { data: response } = await http.get(
                `api/store-locators/${data.government_id}/stores/options`,
                config
            );
            setStores(response);
        } catch (error) {
            console.error("Error fetching stores:", error);
        }
    };

    const getStoreInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        try {
            const { data: response } = await http.get(
                `/api/store-locators/${data.store_locatore_id}`,
                config
            );
            let result = {};
            Object.keys(response).forEach((element) => {
                if (["name", "address", "phone", "government_id"].includes(element)) {
                    result[element] = response[element];
                }
            });
            setData({ ...data, ...result });
        } catch (error) {
            console.error("Error fetching store info:", error);
        }
    };

    async function getGovernments() {
        const { data: response } = await http.get("/api/governments");
        setGovernments(response);
        data["government_id"] = response[0]["value"];
    }

    useEffect(() => {
        getGovernments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.submit_type && data.submit_type !== "store") {
            getStores();
        }
    }, [data.submit_type, data.government_id, data.store_locatore_id]);

    useEffect(() => {
        if (data.store_locatore_id > 0 && data.submit_type && data.submit_type !== "store") {
            getStoreInfo();
            // console.log(data.store_locatore_id);
        }
        setData({ ...data });
    }, [data.store_locatore_id, data.submit_type, data.government_id]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];

        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                fields = [
                    ...fields,
                    {
                        id: "government_id",
                        placeholder: "اختر المحافظة",
                        type: "select",
                        options: governments,
                    },
                    {
                        id: "store_locatore_id",
                        placeholder: "اختر المنفذ المراد تعديله",
                        type: "select",
                        options: stores,
                    },
                ];

                if (data.submit_type === "update") {
                    if (data.store_locatore_id) {
                        fields = [
                            ...fields,
                            {
                                id: "name",
                                placeholder: "اسم المنفذ",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            {
                                id: "address",
                                placeholder: "عنوان المنفذ",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            {
                                id: "phone",
                                placeholder: "رقم التواصل",
                                type: "number",
                                icon: <InputIcon icon="ic:twotone-price-change" />,
                            },

                            // {
                            //     id: "visible",
                            //     placeholder: "ظاهر",
                            //     type: "switch",
                            // },
                        ];
                    }
                }
            }

            if (data.submit_type && data.submit_type === "store") {
                if (isStoreLocatorAvailable) {
                    fields = [
                        ...fields,
                        {
                            id: "government_id",
                            placeholder: "اختر المحافظة",
                            type: "select",
                            options: governments,
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "name",
                        placeholder: "اسم المنفذ",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },

                    {
                        id: "address",
                        placeholder: "عنوان المنفذ",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                    {
                        id: "phone",
                        placeholder: "رقم التواصل",
                        type: "number",
                        icon: <InputIcon icon="ic:twotone-price-change" />,
                    },
                    // {
                    //     id: "visible",
                    //     placeholder: "ظاهر",
                    //     type: "switch",
                    // },
                ];
            }
        }
        setInputFields(fields);
    }, [data.submit_type, data.store_locatore_id, data.government_id, stores]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/store-locators";
            const crudItemName = "المنفذ";
            // console.log(data);
            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.store_locatore_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="auto"
                submitType={data.submit_type}
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default StoreLocatorEditing;
