import React, { useContext } from "react";

import LoadingIcon from "../../../components/ui/LoadingIcon";
import NotificationRow from "./NotificationRow";
import Button from "../../../components/ui/Button";
import NotificationContext from "../../../context/NotificationContext";

const NavbarNotification = () => {
    const { notifications, isLoading, isSecondaryLoading, getNotification } =
        useContext(NotificationContext);
    return (
        <>
            {isLoading ? (
                <LoadingIcon className="font-h1" />
            ) : notifications.length > 0 ? (
                <>
                    <div className="relative clr-text-primary smooth space-y-2">
                        {notifications.map((notification, index) => (
                            <NotificationRow notification={notification} key={index} />
                        ))}
                        {/* <NotificationRow notification={{}} isBorderBottom={false} /> */}
                        <Button
                            className="hover:bg-secondary-container smooth cursor-pointer w-full px-0 !border-0 rounded-none"
                            onClick={getNotification}
                            isLoading={isSecondaryLoading}
                        >
                            عرض المزيد
                        </Button>
                    </div>
                </>
            ) : (
                <div className="text-center"> لا توجد لديك اشعارات</div>
            )}
        </>
    );
};

export default NavbarNotification;
