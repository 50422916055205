import React, { useContext, useEffect, useState } from "react";
import Container from "../components/ui/Container";
import HeaderSection from "../components/ui/HeaderSection";
import { useSearchParams } from "react-router-dom";
import Categorie from "../sections/home/CoursesSection/Categorie";
import AuthContext from "../context/AuthContext";
import auth from "../services/authServices";
import http from "../services/httpServices";
import LoadingIcon from "../components/ui/LoadingIcon";

const SearchResult = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    // const { token, user } = useContext(AuthContext);
    searchParams.get("s");

    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getCourses = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await await http.get("/api/search_courses", {
            ...config,
            params: {
                search: searchParams.get("s"),
            },
        });
        setCourses(data);
        setIsLoading(false);
    };

    useEffect(() => {
        getCourses();
    }, [searchParams]);

    return (
        <>
            <div className="bg-outer-container smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <Container className="py-10 pb-10">
                    <HeaderSection />
                    <div className="font-h3 text-center">لقد بحثت عن : {searchParams.get("s")}</div>
                    {isLoading ? (
                        <div className="flex-center-both">
                            <div className="rounded-md border border-secondary-container flex-center-both px-10 py-5">
                                <div className="flex-center-both space-x-2 space-x-reverse">
                                    <LoadingIcon className="font-h1" />
                                    <div className="">يتم الآن تحميل النتايج</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Categorie
                                titleFirst="الإشتراكات "
                                titleLast="الشهرية"
                                // api={`/api/search_courses/renamed_only`}
                                coursesList={courses.filter((course) => course.is_course_renamed)}
                                noCoursesPlaceholder="لم يتم العثور على نتايج"
                            />
                            <Categorie
                                titleFirst="كورسات "
                                titleLast="منفصلة"
                                coursesList={courses.filter((course) => !course.is_course_renamed)}
                                // api={`/api/search_courses/not_renamed_only`}
                                noCoursesPlaceholder="لم يتم العثور على نتايج"
                            />
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default SearchResult;
